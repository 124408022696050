import React, { useState, useEffect } from 'react';
import './App.css';
import FooterPage from './Pages/FooterPage';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from "./Routes/AppRoutes";
import Navbar from './Layouts/Navbar/Navbar';
import Menu from './Layouts/Menu/Menu';
import SideMenu from './Layouts/SideMenu/SideMenu';
import Cookies from 'js-cookie';
import { AuthProvider } from './Routes/AuthContext';
import { Auth0Provider } from '@auth0/auth0-react';
function App() {
  const [isAdmin, setIsAdmin] = useState(() => Cookies.get('role') === 'admin');
  const [prevRole, setPrevRole] = useState(Cookies.get('role'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const checkCookie = () => {
      const currentRole = Cookies.get('role');
      if (currentRole !== prevRole) {
        setPrevRole(currentRole);
        setIsAdmin(currentRole === 'admin');
      }
    };

    const interval = setInterval(checkCookie, 1000); // Poll every 1 second

    return () => clearInterval(interval);
  }, [prevRole]);

  return (

    <AuthProvider>
    <Router>
      {isAdmin ? (
        <div className="flex flex-col gap-20 lg:gap-0 bg-[#F0F1F3] min-h-screen">
          <div className='w-full '>
            <SideMenu />
          </div>
          <div className='w-full md:w-full lg:w-10/12 xl:w-10/12 mx-auto mr-0  '>
            <AppRoutes />
          </div>
        </div>
      ) : (
        <>
          <Menu />
          <Navbar />
          <div className="w-full">
            <AppRoutes />
          </div>
          <FooterPage />
        </>
      )}
    </Router>
    </AuthProvider>
   
  );
}

export default App;
