import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import profileImage from '../assets/images/model.png';
import SearchMenu from '../Layouts/SearchMenu.js/SearchMenu';
import DivHeading from "../Components/DivHeading/Divheading";
import { getBestSellingProducts} from '../redux/product';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from '../Layouts/Pagination/Pagination';
import ComponentLoader from '../Components/ComponentLoader/ComponentLoader';
import Products from '../Layouts/Products/Products';

import UserSearchMenu from '../Components/UserSearchMenu/UserSearchMenu';
const BestSellingProduct=()=>{
    const dispatch = useDispatch();
    const { bestSellingProducts,totalBestSellingProduct,isBestSellingProductFetching, errorMessage } = useSelector(state => state.productSlice);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPageDropName, setPerPageDropName] = useState(10);
  
    const options = [];

    for (let i = 10; i <= totalBestSellingProduct; i += 10) {
      options.push({ name: i.toString(), id: i });
    }
    
    if (totalBestSellingProduct % 10 !== 0) {
      options.push({ name: totalBestSellingProduct.toString(), id: totalBestSellingProduct });
    }
    useEffect(() => {
        let obj = {
          searchValue: searchQuery,
          perPageCount: perPageDropName,
          pageNumber: currentPage,
        };
        dispatch(getBestSellingProducts(obj));
        return () => {
        //   dispatch(clearProductState());
        
        };
      }, [searchQuery,perPageDropName, currentPage, dispatch]);
    
      
  const searchHandler = (searchValue) => {
    const keywords = searchValue?.split(",").map((keyword) => keyword.trim());
    setSearchQuery(searchValue);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setCurrentPage(1);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onChangePerPage = (obj) => {
    console.log(obj.id,"ok")
    setPerPageDropName(obj.id);
  };

  useEffect(() => {
    if (perPageDropName > 0) {
      setTotalPages(Math.ceil(totalBestSellingProduct / perPageDropName));
    }
  }, [totalBestSellingProduct, perPageDropName]);

    return(
        <>
        <ToastContainer/>
        {(isBestSellingProductFetching) && <ComponentLoader/>}
<div className="flex flex-col gap-5 w-10/12 mx-auto my-5">
        <DivHeading text="Best Selling Product" />
        <div className="flex flex-col justify-center w-full mx-auto gap-5 bg-[#F0F1F3]">
          <UserSearchMenu title={"Search product"} profileImage={profileImage}  searchFunction={searchHandler}  clearSearch={clearSearch} onChangePerPage={onChangePerPage} 
          options={options} 
          dropName={perPageDropName}/>
        </div>
        <div className="flex flex-col w-full gap-3">
          <Products product={bestSellingProducts}/>
        </div>
        <div className="grid grid-cols-1 justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
      </div>
        </>
    )
}

export default BestSellingProduct;