import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { updateCart } from "../../redux/cart";

const CartCard = ({
  imageUrl,
  productName,
  price,
  quantity,
  size,
  color,
  itemId,
  onRemove
}) => {
  console.log("itemId",itemId)
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const handleDecrease = () => {
    if (localQuantity > 1) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      dispatch(updateCart({ itemId, quantity: newQuantity })); 
    }
  };

  const handleIncrease = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    console.log("gjhu",itemId)
    dispatch(updateCart({ itemId, quantity: newQuantity }));
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(); }
  };

  return (
    <div className="flex flex-col md:flex-row p-2 gap-4 shadow-md overflow-hidden bg-white">
      {/* Image Column */}
      <div className="w-4/12">
        <img src={imageUrl} alt={productName} className="w-80 h-44 object-cover" />
      </div>

      {/* Details Column */}
      <div className="w-6/12 flex flex-col justify-between">
        <div className="flex flex-col">
          {/* Product Name */}
          <h2 className="font-semibold mb-2">{productName}</h2>

          {/* Size and Color */}
          <div className="flex items-center justify-between gap-2 mb-2">
            <span className="text-gray-700 text-sm">{size} | {color}</span>
            <div className="flex justify-end">
              <button onClick={handleRemove} className="text-red-500">
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>

          {/* Price */}
          <div className="flex flex-col px-2 md:flex-row justify-between gap-2 mb-2 mt-5 items-center">
            <span className="text-black font-semibold">INR {price}</span>

            {/* Quantity Adjustment */}
            <div className="flex items-center">
              <button onClick={handleDecrease} className="bg-gray-200 text-gray-700 px-3 py-1">
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <span className="mx-3 text-gray-800">{localQuantity}</span>
              <button onClick={handleIncrease} className="bg-gray-200 text-gray-700 px-3 py-1">
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CartCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired, // Unique ID for the item
  onRemove: PropTypes.func, // Optional callback for removing items
};

export default CartCard;
