import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;
let userId;
const setTokenValues = () => {
  const data = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
  token = Cookies.get('token');
  userId=data?.user?._id
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

// Fetch all customers with orders
export const getAllCustomersWithOrders = createAsyncThunk(
  'customers/getAllCustomersWithOrders',
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/getAllCustomersWithOrders?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}&status=${params.status}`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Fetch customer by ID
export const getCustomerById = createAsyncThunk(
  'customers/getCustomerById',
  async (custId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/getCustomerById/${custId}`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// Create a new customer
export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (customerData, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/createOrUpdateCustomer`, {
        method: 'POST',
        headers,
        body: JSON.stringify(customerData),
      });
      const data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update an existing customer
export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ customerId, customerData }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/${customerId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(customerData),
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete a customer
export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async (customerId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/${customerId}`, {
        method: 'DELETE',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return { customerId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const removeFromFavorite = createAsyncThunk(
  'customers/removeFromFavorite',
  async (productId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/removeFromFavorite/${productId}/${userId}`, {
        method: 'DELETE',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return { productId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// Add an invoice to a customer
export const addInvoiceToCustomer = createAsyncThunk(
  'customers/addInvoiceToCustomer',
  async (newInvoice, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/customers/addInvoiceToCustomer`, {
        method: 'POST',
        headers,
        body: JSON.stringify(newInvoice),
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCustomersBillingInvoice = createAsyncThunk(
    'customers/getCustomersBillingInvoice',
    async (params, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/customers/getCustomersBillingInvoice?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}&status=${params.status}`, {
          method: 'GET',
          headers,
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  export const addFavoriteProduct = createAsyncThunk(
    'customer/addFavoriteProduct',
    async (productId, thunkAPI) => {
      setTokenValues();
      try {
        const response = await fetch(`${apiUrl}/customers/addToFav/${userId}`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ productId }),
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  // Fetch favorite products of a user
  export const fetchFavoriteProducts = createAsyncThunk(
    'customer/fetchFavoriteProducts',
    async (_, thunkAPI) => {
      setTokenValues();
      try {
        const response = await fetch(`${apiUrl}/customers/getFavorites/${userId}`, {
          method: 'GET',
          headers,
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const fetchCustomerOrderSummaryCount = createAsyncThunk(
    'customer/fetchCustomerOrderSummaryCount',
    async (customerId, thunkAPI) => {
      setTokenValues();
      try {
        const response = await fetch(`${apiUrl}/orders/customerOrderSummary/${customerId}`, {
          method: 'GET',
          headers,
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const editInvoice = createAsyncThunk(
    'customers/editInvoice',
    async (obj , thunkAPI) => {
      try {
        setTokenValues();
        console.log("onk",obj)
        const response = await fetch(`${apiUrl}/customers/editInvoice`, {
          method: 'PUT',
          headers,
          body: JSON.stringify(obj),
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  )

  export const deleteInvoice = createAsyncThunk(
    'customers/deleteInvoice',
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/customers/deleteInvoice`, {
          method: 'DELETE',
          headers,
          body: JSON.stringify(obj),
        });
        const data = await response.json();
        if (response.status === 200) {
          return response;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  export const subscribeToMail= createAsyncThunk(
    'customers/subscribeToMail',
    async (email, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/subscribe/subscribeMail`, {
          method: 'POST',
          headers,
          body: JSON.stringify(email),
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  // Initial state
const initialState = {
  isFetchingGetAllCustomers: false,
  isFetchingGetCustomerById: false,
  isFetchingCreateCustomer: false,
  isFetchingUpdateCustomer: false,
  isFetchingDeleteCustomer: false,
  isFetchingAddInvoice: false,
  isFetchingGetBillingInvoice: false,
  isFetchingRemoveFavorite: false,
  isFetchingAddFavorite: false,
  isFetchingFetchFavorites: false,
  isFetchingOrderSummary: false,
  
  isBillInvoiceError:false,
  isBillInvoiceSuccess:false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  customers: [],
  totalCustomers: 0,
  customer: null,
  customerBillingInvoice: [],
  userData: [],
  favoriteProducts: [],
  customerBillingInvoiceTotal: 0,
  customerOrderSummaryCount: [],
};

// Customer slice
const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    clearCustomerState: (state) => {
      // Reset all fetching states
      state.isFetchingGetAllCustomers = false;
      state.isFetchingGetCustomerById = false;
      state.isFetchingCreateCustomer = false;
      state.isFetchingUpdateCustomer = false;
      state.isFetchingDeleteCustomer = false;
      state.isFetchingAddInvoice = false;
      state.isFetchingGetBillingInvoice = false;
      state.isFetchingRemoveFavorite = false;
      state.isFetchingAddFavorite = false;
      state.isFetchingFetchFavorites = false;
      state.isFetchingOrderSummary = false;
      state.isBillInvoiceError =false;
      state.isBillInvoiceSuccess=false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.customers = [];
      state.customer = null;
      state.favoriteProducts = [];
      state.customerBillingInvoiceTotal = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomersWithOrders.pending, (state) => {
        state.isFetchingGetAllCustomers = true;
      })
      .addCase(getAllCustomersWithOrders.fulfilled, (state, { payload }) => {
        state.isFetchingGetAllCustomers = false;
        state.isSuccess = true;
        state.customers = payload.customersWithOrders;
        state.totalCustomers = payload?.totalCustomers;
      })
      .addCase(getAllCustomersWithOrders.rejected, (state, { payload }) => {
        state.isFetchingGetAllCustomers = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch customers with orders';
      })

      .addCase(getCustomerById.pending, (state) => {
        state.isFetchingGetCustomerById = true;
      })
      .addCase(getCustomerById.fulfilled, (state, { payload }) => {
        state.isFetchingGetCustomerById = false;
        state.isSuccess = true;
        state.customer = payload.customer;
      })
      .addCase(getCustomerById.rejected, (state, { payload }) => {
        state.isFetchingGetCustomerById = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch customer';
      })

      .addCase(createCustomer.pending, (state) => {
        state.isFetchingCreateCustomer = true;
      })
      .addCase(createCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingCreateCustomer = false;
        state.isSuccess = true;
        state.successMessage = 'Customer created successfully';
        state.customers.push(payload.customer);
      })
      .addCase(createCustomer.rejected, (state, { payload }) => {
        state.isFetchingCreateCustomer = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to create customer';
      })

      .addCase(updateCustomer.pending, (state) => {
        state.isFetchingUpdateCustomer = true;
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingUpdateCustomer = false;
        state.isSuccess = true;
        state.successMessage = 'Customer updated successfully';
        state.customers = state.customers.map((customer) =>
          customer._id === payload.customer._id ? payload.customer : customer
        );
        if (state.customer && state.customer._id === payload.customer._id) {
          state.customer = payload.customer;
        }
      })
      .addCase(updateCustomer.rejected, (state, { payload }) => {
        state.isFetchingUpdateCustomer = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update customer';
      })

      .addCase(deleteCustomer.pending, (state) => {
        state.isFetchingDeleteCustomer = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingDeleteCustomer = false;
        state.isSuccess = true;
        state.successMessage = 'Customer deleted successfully';
        state.customers = state.customers.filter((customer) => customer._id !== payload.customerId);
        if (state.customer && state.customer._id === payload.customerId) {
          state.customer = null;
        }
      })
      .addCase(deleteCustomer.rejected, (state, { payload }) => {
        state.isFetchingDeleteCustomer = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to delete customer';
      })

      .addCase(addInvoiceToCustomer.pending, (state) => {
        state.isFetchingAddInvoice = true;
      })
      .addCase(addInvoiceToCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingAddInvoice = false;
        state.isSuccess = true;
        state.successMessage = 'Invoice added successfully';
      
        const newInvoice = {
          _id: payload.newBillingInvoice.newBillingInvoice._id,
          number: payload.newBillingInvoice.newBillingInvoice.number,
          orderId: payload.newBillingInvoice.newBillingInvoice.orderId,
          customerId: payload.newBillingInvoice.customerId,
          totalAmount: payload.newBillingInvoice.newBillingInvoice.totalAmount,
          billingDetails: payload.newBillingInvoice.newBillingInvoice.billingDetails,
          dateIssued: payload.newBillingInvoice.newBillingInvoice.dateIssued,
          status: payload.newBillingInvoice.newBillingInvoice.status,
        };
      
        // Update the customer’s invoices
        state.customerBillingInvoice = state.customerBillingInvoice.map((customer) => {
          if (customer._id === payload.newBillingInvoice.customerId) {
            return {
              ...customer,
              invoices: [...(customer.invoices || []), newInvoice], // Append the new invoice
            };
          }
          return customer;
        });
      })
      
      .addCase(addInvoiceToCustomer.rejected, (state, { payload }) => {
        state.isFetchingAddInvoice = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to add invoice';
      })

      .addCase(getCustomersBillingInvoice.pending, (state) => {
        state.isFetchingGetBillingInvoice = true;
      })
      .addCase(getCustomersBillingInvoice.fulfilled, (state, { payload }) => {
        state.isFetchingGetBillingInvoice = false;
   
        state.customerBillingInvoice = payload.customers;
        state.customerBillingInvoiceTotal = payload.totalCustomers;
      })
      .addCase(getCustomersBillingInvoice.rejected, (state, { payload }) => {
        state.isFetchingGetBillingInvoice = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch customer billing invoice';
      })

      .addCase(removeFromFavorite.pending, (state) => {
        state.isFetchingRemoveFavorite = true;
      })
      .addCase(removeFromFavorite.fulfilled, (state, { payload }) => {
        state.isFetchingRemoveFavorite = false;
        state.isSuccess = true;
        state.successMessage = 'Product removed from favorites successfully';
        state.favoriteProducts = state.favoriteProducts.filter((product) => product._id !== payload.productId);
      })
      .addCase(removeFromFavorite.rejected, (state, { payload }) => {
        state.isFetchingRemoveFavorite = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to remove product from favorites';
      })

      .addCase(addFavoriteProduct.pending, (state) => {
        state.isFetchingAddFavorite = true;
      })
      .addCase(addFavoriteProduct.fulfilled, (state, { payload }) => {
        state.isFetchingAddFavorite = false;
        state.isSuccess = true;
        state.successMessage = 'Product added to favorites successfully';
        // state.favoriteProducts?.push(payload?.product);
        state.favoriteProducts = payload?.data
      })
      .addCase(addFavoriteProduct.rejected, (state, { payload }) => {
        state.isFetchingAddFavorite = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to add product to favorites';
      })

      .addCase(fetchFavoriteProducts.pending, (state) => {
        state.isFetchingFetchFavorites = true;
      })
      .addCase(fetchFavoriteProducts.fulfilled, (state, { payload }) => {
        state.isFetchingFetchFavorites = false;
        state.isSuccess = true;
        state.favoriteProducts = payload;
      })
      .addCase(fetchFavoriteProducts.rejected, (state, { payload }) => {
        state.isFetchingFetchFavorites = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch favorite products';
      })

      .addCase(fetchCustomerOrderSummaryCount.pending, (state) => {
        state.isFetchingOrderSummary = true;
      })
      .addCase(fetchCustomerOrderSummaryCount.fulfilled, (state, { payload }) => {
        state.isFetchingOrderSummary = false;
        state.isSuccess = true;
        state.customerOrderSummaryCount = payload;
      })
      .addCase(fetchCustomerOrderSummaryCount.rejected, (state, { payload }) => {
        state.isFetchingOrderSummary = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch order summary count';
      })

      .addCase(editInvoice.pending, (state) => {
        state.isFetchingAddInvoice = true;
      })
      .addCase(editInvoice.fulfilled, (state, { payload }) => {
        state.isFetchingAddInvoice = false;
        state.isBillInvoiceSuccess = true;
        state.successMessage = 'Invoice updated successfully';
    
        state.customerBillingInvoice = state.customerBillingInvoice.map(customer => {
          
            const invoiceIndex = customer.invoices.findIndex(
                invoice => invoice._id === payload.invoice._id
            );
    
            if (invoiceIndex !== -1) {
                // Update the specific invoice with the new data
                customer.invoices[invoiceIndex] = {
                    ...customer.invoices[invoiceIndex],
                    ...payload.invoice
                };
            }
    
            return customer; 
        });
    })
    
    
      .addCase(editInvoice.rejected, (state, { payload }) => {
        state.isFetchingAddInvoice = false;
        state.isBillInvoiceError = true;
        state.errorMessage = payload.error || 'Failed to edit invoice';
    })

    .addCase(deleteInvoice.pending, (state) => {
      state.isFetchingAddInvoice = true;
    })
    .addCase(deleteInvoice.fulfilled, (state, { payload }) => {
      // Handle API request completion
      state.isFetchingAddInvoice = false;
      state.isBillInvoiceSuccess = true;
      state.successMessage = 'Invoice deleted successfully';
    
      state.customerBillingInvoice = state.customerBillingInvoice.map(customer => {
        
        const updatedInvoices = customer.invoices.filter(
          invoice => invoice._id !== payload.invoiceId
        );
   
        return {
          ...customer,
          invoices: updatedInvoices
        };
      }) 
    })
    .addCase(deleteInvoice.rejected, (state, { payload }) => {
      state.isFetchingAddInvoice = false;
      state.isBillInvoiceError = true;
      state.errorMessage = payload.error || 'Failed to delete invoice';
    })

    .addCase(subscribeToMail.pending, (state) => {
      state.isFetching= true;
    })
    .addCase(subscribeToMail.fulfilled, (state, { payload }) => {
      state.isFetching= false;
      state.isSuccess = true;
      state.successMessage = 'Subscribed successfully';
     
    })
    .addCase(subscribeToMail.rejected, (state, { payload }) => {
      state.isFetching= false;
      state.isError = true;
      state.errorMessage = payload.error || 'Failed to Subscribed';
    })

  },
});




export const { clearCustomerState } = customerSlice.actions;
export const selectCustomerState = (state) => state.customers;
export default customerSlice;
