import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;

const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Sales Overview
export const getSalesOverview = createAsyncThunk(
  "sales/getSalesOverview",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/salesOverview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Best Selling Products
export const getBestSellingProducts = createAsyncThunk(
  "sales/getBestSellingProducts",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/bestSellingProducts?startDate=${obj?.startDate}&endDate=${obj?.endDate}&limit=${obj?.limit}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Top Selling Stock
export const getTopSellingStock = createAsyncThunk(
  "sales/getTopSellingStock",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/topSellingStock?startDate=${obj?.startDate}&endDate=${obj?.endDate}&limit=${obj?.limit}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Best Selling Categories
export const getBestSellingCategories = createAsyncThunk(
  "sales/getBestSellingCategories",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/bestSellingCategories?startDate=${obj?.startDate}&endDate=${obj?.endDate}&limit=${obj?.limit}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Profit and Revenue
export const getProfitAndRevenue = createAsyncThunk(
  "sales/getProfitAndRevenue",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/profitAndRevenue?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Sales and Purchase Overview
export const getSalesAndPurchaseOverview = createAsyncThunk(
  "sales/getSalesAndPurchaseOverview",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/salesAndPurchaseOverview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOverview = createAsyncThunk(
  "sales/getOverview",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/sales/overview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  isgetSalesOverviewFetching: false,
  isgetBestSellingProductsFetching:false,
  isgetTopSellingStockFetching:false,
  isgetBestSellingCategoriesFetching:false,
  isgetProfitAndRevenueFetching:false,
  isgetSalesAndPurchaseOverview:false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  salesOverview: {},
  bestSellingProducts: [],
  topSellingStock: [],
  bestSellingCategories: [],
  profitAndRevenue: {},
  salesAndPurchaseOverview: {},
  isgetOverview:false,
  overview:[]
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    clearSalesState: (state) => {
        state.isgetSalesOverviewFetching=false;
        state.isgetBestSellingProductsFetching=false;
        state.isgetTopSellingStockFetching=false;
        state.isgetBestSellingCategoriesFetching=false;
        state.isgetProfitAndRevenueFetching=false;
        state.isgetSalesAndPurchaseOverview=false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.salesOverview = {};
      state.bestSellingProducts = [];
      state.topSellingStock = [];
      state.bestSellingCategories = [];
      state.profitAndRevenue = {};
      state.salesAndPurchaseOverview = {};
      state.overview=[];
      state.isgetOverview=false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalesOverview.pending, (state) => {
        state.isgetSalesOverviewFetching = true;
      })
      .addCase(getSalesOverview.fulfilled, (state, { payload }) => {
        state.isgetSalesOverviewFetching = false;
        state.isSuccess = true;
        state.salesOverview = payload;
      })
      .addCase(getSalesOverview.rejected, (state, { payload }) => {
        state.isgetSalesOverviewFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch sales overview';
      })
      
      .addCase(getBestSellingProducts.pending, (state) => {
        state.isgetBestSellingProductsFetching = true;
      })
      .addCase(getBestSellingProducts.fulfilled, (state, { payload }) => {
        state.isgetBestSellingProductsFetching = false;
        state.isSuccess = true;
        state.bestSellingProducts = payload?.bestSellingProducts;
      })
      .addCase(getBestSellingProducts.rejected, (state, { payload }) => {
        state.isgetBestSellingProductsFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch best-selling products';
      })
      
      .addCase(getTopSellingStock.pending, (state) => {
        state.isgetTopSellingStockFetching = true;
      })
      .addCase(getTopSellingStock.fulfilled, (state, { payload }) => {
        state.isgetTopSellingStockFetching = false;
        state.isSuccess = true;
        state.topSellingStock = payload.topSellingStock;
      })
      .addCase(getTopSellingStock.rejected, (state, { payload }) => {
        state.isgetTopSellingStockFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch top-selling stock';
      })

      .addCase(getBestSellingCategories.pending, (state) => {
        state.isgetBestSellingCategoriesFetching = true;
      })
      .addCase(getBestSellingCategories.fulfilled, (state, { payload }) => {
        state.isgetBestSellingCategoriesFetching = false;
        state.isSuccess = true;
        state.bestSellingCategories = payload?.bestSellingCategory;
      })
      .addCase(getBestSellingCategories.rejected, (state, { payload }) => {
        state.isgetBestSellingCategoriesFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch best-selling categories';
      })
      
      .addCase(getProfitAndRevenue.pending, (state) => {
        state.isgetProfitAndRevenueFetching = true;
      })
      .addCase(getProfitAndRevenue.fulfilled, (state, { payload }) => {
        state.isgetProfitAndRevenueFetching = false;
        state.isSuccess = true;
        state.profitAndRevenue = payload?.profitAndRevenue;
      })
      .addCase(getProfitAndRevenue.rejected, (state, { payload }) => {
        state.isgetProfitAndRevenueFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch profit and revenue';
      })

      .addCase(getSalesAndPurchaseOverview.pending, (state) => {
        state.isgetSalesAndPurchaseOverview = true;
      })
      .addCase(getSalesAndPurchaseOverview.fulfilled, (state, { payload }) => {
        state.isgetSalesAndPurchaseOverview = false;
        state.isSuccess = true;
        state.salesAndPurchaseOverview = payload;
      })
      .addCase(getSalesAndPurchaseOverview.rejected, (state, { payload }) => {
        state.isgetSalesAndPurchaseOverview = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch sales and purchase overview';
      })
      .addCase(getOverview.pending, (state) => {
        state.isgetOverview = true;
      })
      .addCase(getOverview.fulfilled, (state, { payload }) => {
        state.isgetOverview = false;
        state.isSuccess = true;
        state.overview = payload;
      })
      .addCase(getOverview.rejected, (state, { payload }) => {
        state.isgetOverview= false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch sales overview';
      })
  },
});

export const { clearSalesState } = salesSlice.actions;
export const selectSalesState = (state) => state.sales;
export default salesSlice;
