import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import productSlice from "./product";
import orderSlice from "./order";
import cartSlice from "./cart";
import salesSlice from "./sales";
import purchaseSlice from "./purchase";
import inventorySlice from "./inventory";
import customerSlice from "./customer";
import categorySlice from "./category";
import supplierSlice from "./supplier";
export const store = configureStore({
  reducer: {
    authentication: authSlice.reducer,
    productSlice:productSlice.reducer,
    orderSlice:orderSlice.reducer,
    cartSlice:cartSlice.reducer,
    salesSlice:salesSlice.reducer,
    purchaseSlice:purchaseSlice.reducer,
    inventorySlice:inventorySlice.reducer,
    customerSlice:customerSlice.reducer,
    categorySlice:categorySlice.reducer,
    supplierSlice:supplierSlice.reducer
  },
});
