import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from "./redux/store"
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));



const clientId = process.env.REACT_APP_CLIENT_ID;

console.log("cleintId",clientId)
root.render(
  <GoogleOAuthProvider clientId={clientId}>
  <Provider store={store}>
  <React.StrictMode>

    <App />
  
  </React.StrictMode>
  </Provider>
  </GoogleOAuthProvider> 
);

reportWebVitals();
