import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;

const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Create a new category
export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (category, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/createCategory`, {
        method: "POST",
        headers,
        body: JSON.stringify(category),
      });
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get all categories
export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/getCategories?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get a category by ID
export const getCategoryById = createAsyncThunk(
  "category/getCategoryById",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/getCategoryById/${id}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update a category
export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (category, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/updateCategory/${category._id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(category),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete a category
export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/deleteCategory/${id}`, {
        method: "DELETE",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCategoryByName = createAsyncThunk(
  "category/getCategoryByName",
  async (name, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/categories/getCategoryByName/${name}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  categories: [],
  totalCategory:0,
  category: {},
  categoryId:''
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    clearCategoryState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      // state.categories = [];
      state.category = {};
      state.categoryId=''
      // state.totalCategory=0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCategory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.categories.push(payload.category);
        state.successMessage = payload.message || 'Category created successfully';
      })
      .addCase(createCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to create category';
      })

      .addCase(getAllCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllCategories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.categories = payload.categories;
        state.totalCategory=payload.totalCategories
      })
      .addCase(getAllCategories.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch categories';
      })

      .addCase(getCategoryById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCategoryById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.category = payload.category;
      })
      .addCase(getCategoryById.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch category';
      })

      .addCase(getCategoryByName.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCategoryByName.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.categoryId = payload.categoryId;
      })
      .addCase(getCategoryByName.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch category';
      })

      .addCase(updateCategory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Category updated successfully';
        const index = state.categories.findIndex(c => c._id === payload.category._id);
        if (index !== -1) {
          state.categories[index] = payload.category;
        }
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update category';
      })

      .addCase(deleteCategory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Category deleted successfully';
        state.categories = state.categories.filter(c => c._id !== payload.categoryId);
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to delete category';
      })
  },
});

export const { clearCategoryState } = categorySlice.actions;
export const selectCategoryState = (state) => state.category;
export default categorySlice;
