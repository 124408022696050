import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
// import icon from "../../assets/images/icon.png";
import companyName from "../../assets/images/icon scrunchie.png";
import Sidebar from "../Sidebar/Sidebar";
import { getUserData, logout } from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useAuth } from "../../Routes/AuthContext";
import debounce from "lodash/debounce";
import { getUniversalSearch } from "../../redux/product";
import { faFirstOrder, faReceipt } from "@fortawesome/free-brands-svg-icons";

const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useSelector((state) => state.authentication);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Debounce search function
  const debouncedSearch = debounce((query) => {
    if (query.trim()) {
      dispatch(getUniversalSearch(query));
      navigate(`/search/${encodeURIComponent(searchValue)}`);
    }
  }, 600);

  const handleCartClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleLogout = () => {
    Cookies.remove("role");
    Cookies.remove("userName");
    Cookies.remove("token");
    Cookies.remove("userEmail");
    Cookies.remove("loginData");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        userId={userData?._id}
      />
      <div className="flex w-full bg-[#f7afba]">
        <div className="flex flex-col md:flex-row justify-between w-11/12 items-center mx-auto px-4 py-2">
          {/* Left Side */}
          <div className="flex space-x-4 text-black pb-4 md:pb-0">
            <NavLink
              to="/contactUs"
              className={({ isActive }) =>
                isActive ? "border-b-2 border-white" : ""
              }
            >
              Contact
            </NavLink>
            <NavLink
              to="/aboutUs"
              className={({ isActive }) =>
                isActive ? "border-b-2 border-white" : ""
              }
            >
              About
            </NavLink>
          </div>

          {/* Center */}
          <div className="flex flex-row items-center hidden md:block">
            {/* <div className="mb-4">
              <img src={icon} alt="Logo" className="w-10 h-10" />
            </div> */}
            <div className="mb-0">
              <img src={companyName} alt="Company Name" className="h-28" />
            </div>
          </div>

          {/* Right Side */}
          <div className="flex flex-row md:flex-row gap-4 space-x-4 text-black">
            <div className="flex items-center w-full bg-white border border-gray-300 rounded-md overflow-hidden">
              <span className="px-2 text-black bg-white">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                id="searchInput"
                type="text"
                placeholder="Search..."
                className="w-full px-2 py-2 text-black focus:outline-none"
                value={searchValue}
                onChange={handleSearchChange}
              />
              {searchValue.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setSearchValue("");
                    navigate(`/`);
                  }}
                  className="text-xl font-bold text-black hover:bg-[#f7afba] hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  &times;
                </button>
              )}
            </div>
            <div className="flex flex-row justify-center items-center gap-4">
             
              {isLoggedIn ? (
                <>
                <FontAwesomeIcon
                icon={faUser}
                className="w-6 h-6 cursor-pointer"
                onClick={()=>navigate('/myprofile')}
              />
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="w-6 h-6 cursor-pointer"
                    onClick={handleCartClick}
                  />
                   <FontAwesomeIcon
                    icon={faFirstOrder}
                    className="w-6 h-6 cursor-pointer"
                    onClick={()=>{navigate('/myOrders')}}
                  />
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => navigate("/favourites")}
                  />
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="w-6 h-6 cursor-pointer"
                    onClick={handleLogout}
                  />
                </>
              ) : (
                <FontAwesomeIcon
                  icon={faSignInAlt}
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => navigate("/login")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
