
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";
// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;

const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Async thunk to fetch purchase overview
export const getInventoryOverview = createAsyncThunk(
    "inventory/getInventoryOverview",
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/inventory/getInventoryOverview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
          method: "GET",
          headers,
        });
        let data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const getLowQuantityStock = createAsyncThunk(
    "inventory/getLowQuantityStock",
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/inventory/getLowQuantityStock?startDate=${obj?.startDate}&endDate=${obj?.endDate}&limit=${obj?.limit}`, {
          method: "GET",
          headers,
        });
        let data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    isInventoryFetching:false,
    isLowQuantityFetching:false,
   inventoryOverview:[],
   lowQuantityStock:[],
   isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  },
  reducers: {
    clearInventoryState: (state) => {
        state.isInventoryFetching=false;
        state.isLowQuantityFetching=false;
        state.isError=false;
        state.isSuccess=false;
        state.errorMessage=false;
        state.successMessage=false;
state.inventoryOverview={}
state.lowQuantityStock=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryOverview.pending, (state) => {
        state.isInventoryFetching=true
      })
      .addCase(getInventoryOverview.fulfilled, (state, {payload}) => {
        state.isInventoryFetching=false;
        state.isSuccess=true;

        state.inventoryOverview = payload;
       
      })
      .addCase(getInventoryOverview.rejected, (state, {payload}) => {
       state.isInventoryFetching=false;
       state.errorMessage=payload?.error
      })

      .addCase(getLowQuantityStock.pending, (state) => {
        state.isLowQuantityFetching=true
      })
      .addCase(getLowQuantityStock.fulfilled, (state, {payload}) => {
        state.isLowQuantityFetching=false;
        state.isSuccess=true;

        state.lowQuantityStock = payload?.lowQuantityStock;
       
      })
      .addCase(getLowQuantityStock.rejected, (state, {payload}) => {
       state.isLowQuantityFetching=false;
       state.errorMessage=payload?.error
      });
  }
});


export const { clearInventoryState } = inventorySlice.actions;
export const selectInventoryState = (state) => state.inventory;
export default inventorySlice;
