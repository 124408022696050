import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DivHeading from "../Components/DivHeading/Divheading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComponentLoader from "../Components/ComponentLoader/ComponentLoader";
import ProductCard from "../Components/ProductCard/ProductCard";
import { useParams } from "react-router-dom";
import { getUniversalSearch } from "../redux/product";
const SearchPage = () => {
  const { query } = useParams(); // Get search query from URL parameters

  const dispatch = useDispatch();
  const { universalSearch, isFetching, error } = useSelector((state) => state.productSlice);

  useEffect(() => {
    if (query) {
      dispatch(getUniversalSearch(query))
        .unwrap()
        .catch((err) => {
          toast.error("Error fetching search results");
          console.error(err);
        });
    }
  }, [query, dispatch]);

  return (
    <>
      <ToastContainer />
      {isFetching && <ComponentLoader />}
      {error && <p className="text-red-500">Error: {error.message}</p>}
      <div className="flex flex-col gap-5 w-10/12 mx-auto my-5">
        <DivHeading text="Search Result" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pt-5">
          {universalSearch?.length > 0 ? (
            universalSearch?.map((product) => (
              <ProductCard
                key={product._id}
                id={product._id}
                imageUrl={product.image[0]} // Assuming the first image is the primary one
                productName={product.productName}
                rating={product.productRatings.averageRating} // Adjust as needed if there are rating details
                description={product.productDesc}
                price={product.productPrice}
                productBrand={product.productBrand}
                productStock={product.productStock}
                expiryDate={product.expiryDate}
                productSize={product.productSize}
                productColor={product.productColor}
              />
            ))
          ) : (
            <p>No results found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPage;
