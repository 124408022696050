import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import "./navbar.css";
// import icon from "../../assets/images/icon.png";
import companyName from "../../assets/images/icon scrunchie.png";
import Underline from '../../Components/Underline/Underline';
import { getAllCategories } from '../../redux/category';

const Navbar = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.categorySlice);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); 

  useEffect(() => {
    dispatch(getAllCategories({
      searchValue: '',
      perPageCount: '',
      pageNumber: '',
    }));
  }, [dispatch]);

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/allBrands', label: 'All Scrunchies' }, // Brands
  
    { path: '/clothing', label: 'Filter' }, // clothing
   
    
    ...categories.map(category => ({
      path: `/navproducts/${category._id}`,
      label: category.name
    }))
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white p-2 mx-auto">
      <div className="container mx-auto relative flex justify-between items-center">
        {/* Logo Section for Mobile */}
        {!isOpen && (
          <div className="flex flex-row items-center md:invisible">
            {/* <div className="mb-4">
              <img src={icon} alt="Logo" className="w-10 h-10" />
            </div> */}
            <div className="mb-4">
              <img src={companyName} alt="Company Name" className="h-16" />
            </div>
          </div>
        )}

        {/* Toggle Button for Mobile */}
        <div className="block lg:hidden absolute right-2 top-2">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}></path>
            </svg>
          </button>
        </div>

        {/* Navigation Links */}
        <div className={`w-full block lg:flex lg:items-center lg:justify-center lg:w-full hide-scrollbar overflow-x-scroll no-scrollbar ${isOpen ? '' : 'hidden'} lg:space-x-4`}>
          <div className="flex flex-col lg:flex-row lg:space-x-4 justify-center items-center w-full">
            {navItems.map(({ path, label }) => (
              <Link to={path} key={path} className="navItems capitalize py-2 px-4">
                {label}
                {isActive(path) && <Underline />}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
