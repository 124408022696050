import React from 'react';

const Dropdown = ({ label, options, selected, onChange }) => {
  const placeholder = "Select an option";

  return (
    <div className="relative">
      {/* Dropdown */}
      {/* <label className="block mb-1 text-sm font-medium text-gray-700">{label}</label> */}
      <select
        value={selected || ""} // Ensure default is an empty string if no selection
        onChange={(e) => onChange({id:e.target.value})} // Pass the value directly
        className="block w-full border border-gray-300 p-2"
      >
        {/* Default Option */}
        <option value="" disabled>
          {selected ? options.find(option => option._id === selected)?.name || placeholder : placeholder}
        </option>

        {/* Options */}
        {options.map(option => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
