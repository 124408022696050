import { useState  } from 'react';
import { Dialog } from "@headlessui/react";
import {
  MenuIcon,
  XIcon,

  ClipboardCheckIcon,
  CalendarIcon,
  TemplateIcon,
  MailIcon,
 
  LogoutIcon,
  HomeIcon,
 
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
// import icon from '../../assets/images/icon.png';
import companyName from '../../assets/images/icon scrunchie.png';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth";
export default function SideMenu({ isSidebarVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get("role"));


//   useEffect(() => {
//     const rolesFromCookie = Cookies.get("role");
//     if (rolesFromCookie) {
//       setIsLoggedIn(true);
//       setRoles(JSON.parse(rolesFromCookie));
//     }
//   }, []);

  const handleLogout = () => {
    Cookies.remove("role");
    Cookies.remove("userName");
    Cookies.remove("token");
    Cookies.remove("userEmail");
    Cookies.remove("loginData")
    dispatch(logout());
   
    navigate("/login");
  };

  // const handleMenuCloseAndLogout = () => {
  //   setMobileMenuOpen(false);
  //   // handleLogout();
  // };

  // if (!isLoggedIn) {
  //   return null;
  // }

//   const isAdmin = roles.includes("admin");

  return (
    <aside className={`w-full w-full md:w-full lg:w-2/12 xl:w-2/12 justify-center items-center overflow-visible lg:block fixed lg:left-0 top-0 lg:h-screen bg-white z-50 shadow-md overflow-y-auto transition-all duration-300`} style={{backgroundColor:' #f7afba',overflow:'visible'}}>
      {/* Desktop Header */}
      <div className="hidden lg:flex items-center justify-between p-4 lg:p-6">
        <div className="flex items-center justify-center text-black">
          {/* <img src={icon} alt='icon'  className=' h-10 md:h-12 xl:h-16'/> */}
          <img src={companyName} alt='cn'  className=' h-10 md:h-10 xl:h-16' />
        </div>
      </div>

      {/* Mobile Header */}
      <div className="lg:hidden flex items-center justify-between p-4 lg:p-6">
        <div className="flex items-center justify-around">
          {/* <img src={icon} alt='icon' className='h-10'/> */}
          <img src={companyName} alt='cn' className=' h-10'/>
        </div>
        <button
          className="focus:outline-none"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? (
            <XIcon className="h-6 w-6 text-gray-500" />
          ) : (
            <MenuIcon className="h-6 w-6 text-gray-500" />
          )}
        </button>
      </div>

      {/* Sidebar Navigation */}
      <nav className="hidden lg:block px-2 lg:px-2 overflow-visible">
        <div className="space-y-1 overflow-visible flex flex-col justify-center items-left">
        
            <>
              <NavLinkWithIcon to="/dashboard" icon={HomeIcon} label="Dashboard" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
              <NavLinkWithIcon to="/inventory" icon={ClipboardCheckIcon} label="Inventory" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
              <NavLinkWithIcon to="/billing-invoices" icon={CalendarIcon} label="Billing & Invoices" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
              <NavLinkWithIcon to="/customer" icon={MailIcon} label="Customers" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible} />
              <NavLinkWithIcon to="/category" icon={ClipboardCheckIcon} label="Product Category" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
              {/* <NavLinkWithIcon to="/supplier" icon={ClipboardCheckIcon} label="Supplier" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
             */}
              <NavLinkWithIcon to="/order" icon={TemplateIcon} label="Order" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible}/>
              <NavLinkWithIcon to="/reports" icon={MailIcon} label="Reports" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible} />
            </>
          
        </div>

        <div className="mt-auto pt-4">
          {/* <NavLinkWithIcon to="/changePassword" icon={LockClosedIcon} label="Change Password" onClick={() => setMobileMenuOpen(false)} isSidebarVisible={isSidebarVisible} /> */}
          <NavLinkWithIcon to="/login" icon={LogoutIcon} label="Log out" onClick={() => { setMobileMenuOpen(false); handleLogout(); }} isSidebarVisible={isSidebarVisible}/>
        </div>
      </nav>

      {/* Mobile Menu Dialog */}
      <Dialog
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        className="lg:hidden fixed inset-0 z-20"
      >
        <div className="flex justify-end p-4">
          <button className="focus:outline-none" onClick={() => setMobileMenuOpen(false)}>
            <XIcon className="h-6 w-6 text-gray-500" />
          </button>
        </div>
        <div className=" px-2 pt-2 pb-8 space-y-1" style={{backgroundColor:'#f7afba'}}>
         
            <>
              <NavLinkWithIcon to="/dashboard" icon={HomeIcon} label="Dashboard" onClick={() => setMobileMenuOpen(false)} />
              <NavLinkWithIcon to="/inventory" icon={ClipboardCheckIcon} label="Inventory" onClick={() => setMobileMenuOpen(false)} />
              <NavLinkWithIcon to="/billing-invoices" icon={CalendarIcon} label="Billing & Invoices" onClick={() => setMobileMenuOpen(false)} />
              <NavLinkWithIcon to="/customer" icon={MailIcon} label="Customer" onClick={() => setMobileMenuOpen(false)} />
              <NavLinkWithIcon to="/category" icon={ClipboardCheckIcon} label="Product Category" onClick={() => setMobileMenuOpen(false)} />
              {/* <NavLinkWithIcon to="/supplier" icon={ClipboardCheckIcon} label="Supplier" onClick={() => setMobileMenuOpen(false)} />
             */}
              <NavLinkWithIcon to="/order" icon={TemplateIcon} label="Order" onClick={() => setMobileMenuOpen(false)} />
              <NavLinkWithIcon to="/reports" icon={MailIcon} label="Reports" onClick={() => setMobileMenuOpen(false)} />
            </>
          
        </div>

        <div className="bg-white border-t pt-4 pb-2" style={{backgroundColor:'#f7afba'}}>
          {/* <NavLinkWithIcon to="/changePassword" icon={LockClosedIcon} label="Change Password" onClick={() => setMobileMenuOpen(false)} /> */}
          <NavLinkWithIcon to="/login" icon={LogoutIcon} label="Log out" onClick={() => { setMobileMenuOpen(false); handleLogout(); }} />
        </div>
      </Dialog>
    </aside>
  );
}

function NavLinkWithIcon({ to, icon: Icon, label, onClick ,isSidebarVisible }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <div className={`relative flex items-center ${isSidebarVisible ? "justify-center " : "" }overflow-visible`}  onMouseLeave={handleMouseLeave}
       onMouseEnter={handleMouseEnter} >
      <NavLink
        to={to}
        end
        onClick={onClick}
        className={({ isActive }) =>
          `flex items-center py-2 px-4 transition duration-200 ${
            isActive
              ? 'text-[#0e9fad] '
              : 'text-black hover:text-[#0e9fad] '
          }`
        }
    
      >
        <Icon className={`${isSidebarVisible ? 'h-7 w-7 ' : 'text-bold h-6 w-6 mr-4'}`} />
        <span className={`${isSidebarVisible ? 'hidden' : 'lg:inline-block'}`}>{label}</span>
      </NavLink>
      {isHovered && isSidebarVisible && (
        <div className="absolute overflow-visible top-full top-1/2 transform -translate-y-1/2 ml-2 p-2 text-sm bg-gray-100 text-indigo-600 rounded-md shadow-lg z-50 whitespace-nowrap">
          {label}
        </div>
     )}
    </div>
    </> 
  );
}


