// src/store/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialStateValues = {
  isAuthSliceFetching: false,
  isAuthSliceSuccess: false,
  isAuthSliceError: false,
  authSliceErrorMessage: '',
  authSliceSuccessMessage: '',
  isAuthSliceSignUpSuccess :false,

  userData:null,
  user: null, // Change to null instead of empty array
  isAdmin: false, // Add isAdmin field
  allUsers:[]
};

const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;
let userId;
const setTokenValues = () => {
  const data = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
  token = Cookies.get('token');
  userId=data?.user?._id
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};
// Async thunk for login
export const loginUser = createAsyncThunk('loginUser', async (obj, thunkAPI) => {
  try {
    const response = await fetch(`${apiUrl}/auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: 'include', // Correct usage for fetch API
      body: JSON.stringify(obj),
    });
    const data = await response.json();
    if (response.status === 200) {
      Cookies.set('loginData', JSON.stringify(data));
      Cookies.set('token', data.token);
      Cookies.set('userName', data.user.firstName + ' ' + data.user.lastName);
      Cookies.set('userEmail', data.user.email);
      Cookies.set('role', data.user.role);
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response?.data || 'Something went wrong');
  }
});

export const googleLoginUser = createAsyncThunk('loginWithGoogle', async (obj, thunkAPI) => {
  try {
    const response = await fetch(`${apiUrl}/auth/loginWithGoogle`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: 'include', // Correct usage for fetch API
      body: JSON.stringify(obj),
    });
    const data = await response.json();
    if (response.status === 200) {
      Cookies.set('loginData', JSON.stringify(data));
      Cookies.set('token', data.token);
      Cookies.set('userName', data.user.firstName + ' ' + data.user.lastName);
      Cookies.set('userEmail', data.user.email);
      Cookies.set('role', data.user.role);
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response?.data || 'Something went wrong');
  }
});

// Async thunk for signup
export const signupUser = createAsyncThunk('signupUser', async (obj, thunkAPI) => {
  try {
    const response = await fetch(`${apiUrl}/auth/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: 'include',
      body: JSON.stringify(obj),
    });
    const data = await response.json();
    if (response.status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response?.data || 'Something went wrong');
  }
});

export const getUserData = createAsyncThunk(
  'auth/getUserData',
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/auth/getUserData/${userId}`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const  getAllUsers= createAsyncThunk(
  'auth/getAllUsers',
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/auth/getAllUsers`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (order, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/auth/updateUser/${userId}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(order),
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const authSlice = createSlice({
  name: 'authentication',
  initialState: initialStateValues,
  reducers: {
    logout: (state) => {
      // Clear all cookies
      Cookies.remove('loginData');
      Cookies.remove('token');
      Cookies.remove('userName');
      Cookies.remove('userEmail');
      Cookies.remove('role');
      
      // state.user = null;
      // Reset state to initial values
      return { ...initialStateValues }; // Ensure you're returning a new object here
    },
    clearAllSliceStates: (state) => {
      state.authSliceSuccessMessage = '';
      state.authSliceErrorMessage = '';
      state.isAuthSliceError = false;
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = false;
      state.isAuthSliceSignUpSuccess = false;
      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.user = payload?.user;
      state.isAdmin = payload?.user?.role === 'admin'; 
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.message || 'Something Went Wrong';
    });
    builder.addCase(loginUser.pending, (state) => {
      state.isAuthSliceFetching = true;
    });

    builder.addCase(signupUser.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSignUpSuccess = true;
    });
    builder.addCase(signupUser.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.Message || 'Signup Failed';
    });
    builder.addCase(signupUser.pending, (state) => {
      state.isAuthSliceFetching = true;
    })
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.userData = payload;
     
    });
    builder.addCase(getUserData.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.message || 'Something Went Wrong';
    });
    builder.addCase(getUserData.pending, (state) => {
      state.isAuthSliceFetching = true;
    })

    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.allUsers = payload;
     
    });
    builder.addCase(getAllUsers.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.message || 'Something Went Wrong';
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.isAuthSliceFetching = true;
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.userData=payload?.user;
     
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.message || 'Something Went Wrong';
    });
    builder.addCase(updateUser.pending, (state) => {
      state.isAuthSliceFetching = true;
    })

    builder.addCase(googleLoginUser.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.user = payload?.user;
      state.isAdmin = payload?.user?.role === 'admin'; 
    });
    builder.addCase(googleLoginUser.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.message || 'Something Went Wrong';
    });
    builder.addCase(googleLoginUser.pending, (state) => {
      state.isAuthSliceFetching = true;
    });

  },
});

export const { logout, clearAllSliceStates } = authSlice.actions;
export default authSlice;
