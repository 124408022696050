// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => !!Cookies.get('role'));

  useEffect(() => {
    const checkCookie = () => {
      const currentRole = Cookies.get('role');
      setIsLoggedIn(!!currentRole);
    };

    // Check cookie immediately and set up polling
    checkCookie();
    const interval = setInterval(checkCookie, 1000); // Poll every 1 second

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
