import React from 'react';
import DivHeading from '../../Components/DivHeading/Divheading';
import tribune from "../../assets/images/p1.jpeg";
import times from "../../assets/images/p2.jpeg";
import mint from "../../assets/images/p3.jpg";
import hindustan from "../../assets/images/p4.jpeg";
import hindu from "../../assets/images/p5.png";
import economic from "../../assets/images/p6.jpeg";
import asian from "../../assets/images/p7.jpeg";
import hello from "../../assets/images/p8.png";
// import bloom from "../../assets/images/bloom.jpg";

const imagePaths = [
  tribune,
  times,
  mint,
  hindustan,
  hindu,
  economic,
  hello,
  // bloom,
  asian,
];

const LuxuryIn = () => {
  return (
    <div className="flex flex-col justify-center py-5 w-full mx-auto" style={{ backgroundColor: '#F9F8F4' }}>
      <div className="flex flex-col justify-center items-center w-10/12 mx-auto">
        <DivHeading text="Our Scrunchie Studio Seen In" />
        <div className="grid grid-cols-3 md:grid-cols-5 justify-center items-center gap-4 mx-auto">
          {imagePaths.map((imagePath, index) => (
            <div key={index} className="flex justify-center items-center w-9/12 mx-auto my-4">
              <div className="w-full aspect-[16/9]">
                <img src={imagePath} alt={`luxury-image-${index}`} className="object-contain w-full h-full" />
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default LuxuryIn;
