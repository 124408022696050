import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faPinterest, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';

const SocialIcons = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 p-5 ">
              
      {/* <div className="group flex justify-center items-center">
        <FontAwesomeIcon 
          icon={faTwitter} 
          className="text-[#0e9fad] group-hover:text-yellow-600 transition-colors duration-300 border border-[#0e9fad] group-hover:border-yellow-600 rounded-full p-4" 
          size="2x" 
        />
      </div> */}
      <NavLink to='#' className="group flex justify-center items-center">
        <FontAwesomeIcon 
          icon={faFacebook} 
          className="text-[#0e9fad] group-hover:text-yellow-600 transition-colors duration-300 border border-[#0e9fad] group-hover:border-yellow-600 rounded-full p-4" 
          size="2x" 
        />
      </NavLink>
      {/* <div className="group flex justify-center items-center">
        <FontAwesomeIcon 
          icon={faPinterest} 
          className="text-[#0e9fad] group-hover:text-yellow-600 transition-colors duration-300 border border-[#0e9fad] group-hover:border-yellow-600 rounded-full p-4" 
          size="2x" 
        />
      </div> */}
      <NavLink to='#' className="group flex justify-center items-center">
        <FontAwesomeIcon 
          icon={faYoutube} 
          className="text-[#0e9fad] group-hover:text-yellow-600 transition-colors duration-300 border border-[#0e9fad] group-hover:border-yellow-600 rounded-full p-4" 
          size="2x" 
        />
      </NavLink>
      <NavLink to='https://www.instagram.com/ourscrunchies.studio' className="group flex justify-center items-center">
        <FontAwesomeIcon 
          icon={faInstagram} 
          className="text-[#0e9fad] group-hover:text-yellow-600 transition-colors duration-300 border border-[#0e9fad] group-hover:border-yellow-600 rounded-full p-4" 
          size="2x" 
        />
      </NavLink>
    </div>
  );
};

export default SocialIcons;
