import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;
let userId
const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  userId = data?.user?._id
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Add an item to the cart
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (item, thunkAPI) => {
    try {
      setTokenValues();
      
      // Adding userId to the item object
      const itemWithUserId = { ...item, userId };
      console.log("irsm",itemWithUserId)
      const response = await fetch(`${apiUrl}/cart/addToCart`, {
        method: "POST",
        headers,
        body: JSON.stringify(itemWithUserId),
      });
      
      let data = await response.json();
      
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get the cart for a user
export const getCart = createAsyncThunk(
  "cart/getCart",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/cart/getCart/${userId}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update the quantity of a product in the cart
export const updateCart = createAsyncThunk(
  'cart/updateCart',
  async ({ itemId, quantity }, thunkAPI) => {
    try {
      const response = await fetch(`${apiUrl}/cart/updateCart/${itemId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
         
        },
        body: JSON.stringify({ quantity }),
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Remove an item from the cart
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (itemId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/cart/removeFromCart/${itemId}/${userId}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          // Include any other headers you might need
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Clear the cart for a user
export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (userId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/cart/clearcart/${userId}`, {
        method: "DELETE",
        headers,
        body: JSON.stringify({ userId }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  cart: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCartState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.cart = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Item added to cart successfully';
        state.cart = payload.cart; // assuming the response contains the updated cart
      })
      .addCase(addToCart.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to add item to cart';
      })

      .addCase(getCart.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCart.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.cart = payload.cart;
      })
      .addCase(getCart.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch cart';
      })

      .addCase(updateCart.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateCart.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Cart updated successfully';
        state.cart = payload.cart; 
      })
      .addCase(updateCart.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update cart';
      })

      .addCase(removeFromCart.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(removeFromCart.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Item removed from cart successfully';
        state.cart = payload.cart; // assuming the response contains the updated cart
      })
      .addCase(removeFromCart.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to remove item from cart';
      })

      .addCase(clearCart.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = 'Cart cleared successfully';
        state.cart = [];
      })
      .addCase(clearCart.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to clear cart';
      });
  },
});

export const { clearCartState } = cartSlice.actions;
export const selectCartState = (state) => state.cart;
export default cartSlice;
