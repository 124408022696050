// redux/purchaseSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";
// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;

const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Async thunk to fetch purchase overview
export const getPurchaseOverview = createAsyncThunk(
    "purchase/getPurchaseOverview",
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/purchase/purchaseOverview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
          method: "GET",
          headers,
        });
        let data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: {
    isFetching:false,
   purchaseOverview:[],
   isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  },
  reducers: {
    clearPurchaseState: (state) => {
        state.isFetching=false;
        state.isError=false;
        state.isSuccess=false;
        state.errorMessage=false;
        state.successMessage=false;
state.purchaseOverview={}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPurchaseOverview.pending, (state) => {
        state.isFetching=true
      })
      .addCase(getPurchaseOverview.fulfilled, (state, {payload}) => {
        state.isFetching=false;
        state.isSuccess=true;

        state.purchaseOverview = payload;
       
      })
      .addCase(getPurchaseOverview.rejected, (state, {payload}) => {
       state.isFetching=false;
       state.errorMessage=payload?.error
      });
  }
});


export const { clearPurchaseState } = purchaseSlice.actions;
export const selectPurchaseState = (state) => state.purchase;
export default purchaseSlice;
