import React, { useState, useEffect } from "react";
import ProductCard from "../../Components/ProductCard/ProductCard";

const Products = ({ product }) => {
  const [products, setProducts] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    console.log("Latest Products:", product);
    // Update the products state whenever latestProducts changes
    if (product && Array.isArray(product)) {
      setProducts(product);
    }
  }, [product]);

  const handleToggleFavorite = () => {
    setIsFavorite((prev) => !prev);
  };
  console.log("productsss", products);
  return (
    <div className="flex flex-col justify-center py-5 w-11/12 mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pt-5">
        {products.map((product, index) => (
          <ProductCard
            key={index}
            id={product._id || product.product._id}
            imageUrl={
              (product.image && product.image.length > 0
                ? product.image[0]
                : null) ||
              (product.product &&
              product.product.image &&
              product.product.image.length > 0
                ? product.product.image[0]
                : null) ||
              "default-image-url"
            }
            productName={product.productName || product.product.productName}
            rating={
              product.productRatings?.averageRating || product.product.productRatings?.averageRating 
              // product?.product?.productRatings?.ratingCount ||
              // 0
            }
            description={product.productDesc || product.product.productDesc}
            price={product.productPrice || product.product.productPrice}
            productBrand={product.productBrand || product.product.productBrand}
            productStock={product.productStock || product.product.productStock}
            expiryDate={product.expiryDate || product.product.expiryDate}
            productSize={product.productSize || product.product.productSize}
            productColor={product.productColor || product.product.productColor}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
