import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  name,
  bg = 'white',
  color = 'black',
  className = '',
  onClick,
  icon,
  hoverbg = '#e9e5e5',
  hoverText = 'black'
}) => {
  const buttonStyle = {
    backgroundColor: bg,
    color: color,
    transition: 'background-color 0.3s, color 0.3s'
  };

  const hoverStyle = {
    '--hover-bg': hoverbg,
    '--hover-text': hoverText
  };

  return (
    <button
    type='submit'
      style={buttonStyle}
      className={`${className} py-2 px-4 transition duration-300`}
      onClick={onClick}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = hoverStyle['--hover-bg'];
        e.currentTarget.style.color = hoverStyle['--hover-text'];
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
        e.currentTarget.style.color = buttonStyle.color;
      }}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {name}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  hoverbg: PropTypes.string,
  hoverText: PropTypes.string
};

export default Button;
