import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CartCard from "../../Components/CartCard/CartCard";
import { useNavigate } from "react-router-dom";
import { getCart, removeFromCart } from "../../redux/cart";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Routes/AuthContext";
import ComponentLoader from "../../Components/ComponentLoader/ComponentLoader";
import ConfirmationPopup from "../../Popups/ConfirmationPopup/ConfrimationPopup";

const Sidebar = ({ isOpen, onClose }) => {
  const { cart, isFetching } = useSelector((state) => state.cartSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  const calculateSubtotal = () => {
    return cart?.items?.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2) || '0.00';
  };

  const handleCheckoutClick = () => {
    navigate("/checkout");
    onClose();
  };

  const handleRemoveClick = (itemId) => {
    setItemToRemove(itemId);
    setShowConfirmation(true);
  };

  const confirmRemoveItem = () => {
    dispatch(removeFromCart(itemToRemove));
    setShowConfirmation(false);
  };

  return (
    <>
      {isFetching && <ComponentLoader />}
      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-70 backdrop-blur-sm z-10"
          onClick={onClose}
          aria-label="Close sidebar overlay"
        ></div>
      )}

      <div
        className={`fixed top-0 right-0 h-full w-11/12 md:w-7/12 lg:w-4/12 bg-white shadow-lg transition-transform transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } z-20`}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-end p-4">
            <button onClick={onClose} aria-label="Close sidebar">
              <FontAwesomeIcon icon={faTimes} className="text-gray-700 text-2xl" />
            </button>
          </div>
          <div className="flex flex-col flex-grow overflow-y-auto p-3">
            <h2 className="text-xl font-bold mb-4">YOUR CART</h2>
            {cart?.items?.length > 0 ? (
              <div className="flex flex-col">
                {cart.items.map((item) => (
                  <CartCard
                    key={item.id}
                    itemId={item._id} // Pass item ID for updating quantity
                    imageUrl={item.product.image}
                    productName={item.product.productName}
                    price={item.price}
                    quantity={item.quantity}
                    size={item.size}
                    color={item.color}
                    onRemove={() => handleRemoveClick(item._id)}
                  />
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-500 text-center">Your cart is empty</p>
              </div>
            )}
          </div>
          <div className="p-4 border-t border-gray-200 bg-white">
            <div className="flex justify-between text-lg font-bold mb-4">
              <span className="font-semibold">Subtotal</span>
              <span className="font-semibold">INR {calculateSubtotal()}</span>
            </div>
            {cart?.items?.length > 0 && (
              <button
                className="w-full bg-black text-black py-2 hover:bg-gray-700"
                onClick={handleCheckoutClick}
                aria-label="Continue to checkout"
              >
                CONTINUE TO CHECKOUT
              </button>
            )}
            <span className="mx-auto flex justify-center items-center p-4 text-xs font-semibold">
              Past, get it now before it sells out.
            </span>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationPopup
          confirmTitle="Remove Item"
          confirmDesc="Are you sure you want to remove this item from your cart?"
          onClose={() => setShowConfirmation(false)}
          onConfirm={confirmRemoveItem}
        />
      )}
    </>
  );
};

export default Sidebar;
