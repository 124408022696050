// src/Routes/PrivateRoutes.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivateRoute = ({ element, roles }) => {
  const role = Cookies.get('role');

  // Check if the user has the required role
  const hasRole = roles.includes(role);

  // Redirect to login if not authenticated or authorized
  if (!hasRole) {
    return <Navigate to="/login" replace />;
  }

  // Render the component if authorized
  return element;
};

export default PrivateRoute;
