import React from "react";
import DivHeading from "../../Components/DivHeading/Divheading";
import "./trustpilot.css";
// import trustpilot from "../../assets/images/trustpilot.png";
import SocialIcons from "../SocialLinks/SocialLinks";
const TrustPilot = () => {
  return (
    <div className=" flex flex-col justify-center items-center py-5 w-11/12 mx-auto">
      {/* <div className="flex flex-col m-5 justify-center items-center">
        <p className="ratingtrust">9.8/10</p>
        <img src={trustpilot} alt="trustpilot" className="w-8/12 " />
      </div> */}
      <div className="w-full mx-auto">
      <DivHeading
        text="Our Scrunchie Studio on Social"
        desc="Follow us for the latest scrunchie styles, deals, and inspo!"
      />
      </div>

      <SocialIcons />
    </div>
  );
};

export default TrustPilot;
