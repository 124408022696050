import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;
let userId
const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  userId = data?.user?._id
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Create a new product
export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (product, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/createProducts`, {
        method: "POST",
        headers,
        body: JSON.stringify(product),
      });
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get all products
export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getAllProducts?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllPopularProduct= createAsyncThunk(
  "product/getAllPopularProduct",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getAllPopularProduct?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllBrands= createAsyncThunk(
  "product/getAllBrands",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getAllBrands?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllProductByCatId = createAsyncThunk(
  "product/getAllProductByCatId ",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      console.log("filtr",params.filters)
      const response = await fetch(`${apiUrl}/products/getAllProductByCatId/${params.categoryId}?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}&color=${params?.color || "" }&size=${params?.size || ""}&price=${params?.price || "" }&gender=${params?.gender || ""}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllProductsByBrand = createAsyncThunk(
  "product/getAllProductsByBrand ",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getAllProductsByBrand/${params.brand}?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllProductsByGender = createAsyncThunk(
  "product/getAllProductsByGender",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getAllProductsByGender/${params.gender}?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// Get a product by ID
export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getProductById/${id}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update a product
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (product, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/updateProduct/${product._id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(product),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const setProductRating = createAsyncThunk(
  "product/setProductRating",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/setProductRating/${userId}`, {
        method: "POST",
        headers,
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// Delete a product
export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/deleteProduct/${id}`, {
        method: "DELETE",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInventorySummary = createAsyncThunk(
  "product/getInventorySummary",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getInventorySummary/?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProductSummary = createAsyncThunk(
  "product/getProductSummary",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getProductOverview?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getLatestProducts = createAsyncThunk(
  "product/getLatestProducts",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getLatestProducts?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProductsByRandomCategory = createAsyncThunk(
  "product/getProductsByRandomCategory",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getProductsByRandomCategory?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getRandomBrands = createAsyncThunk(
  "product/getRandomBrands",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getRandomBrands`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getBestSellingProducts = createAsyncThunk(
  "product/getBestSellingProducts",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/getBestSellingProducts?searchQuery=${params?.searchValue}&page=${params.pageNumber}&limit=${params.perPageCount}`, {
        method: "GET",
        headers,
       
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUniversalSearch = createAsyncThunk(
  "product/getUniversalSearch",
  async (query, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/products/universalSearch?searchQuery=${query}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const initialState = {
  isProductSummaryFetching:false,
  islatestProductFetching:false,
  isBestSellingProductFetching:false,
  isInventoryFetching:false,
  isAllBrandsFetching:false,
  isAllProductsByBrandFetching:false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  products: [],
  product: {},
  inventorySummary:{},
  productSummary:{},
  latestProducts:[],
  totalProducts: 0,
  catProducts:[],
  allBrands:[],
  allBrandProducts:[],
  totalLatestProduct:0,
  totalBrandProducts:0,
  totalBrandItems:0,
  universalSearch:[],
  bestSellingProducts:[],
  totalBestSellingProduct:0,
  productRating:0,
  totalGenderProducts:0,
  allGenderProducts:[],
  allPopularProduct:[],
  ProductsByRandomCategory:[],
  randomBrands:[],
  isProductsByRandomCategoryFetching:false,
  isRandomBrandsFetching:false,
  isAllPopularProductFetching:false,
  isAllProductByGenderFetching:false,
  totalProductsByRandomCategory:0,
  totalPopularProducts:0
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearProductState: (state) => {
      state.isProductSummaryFetching = false;
      state.isInventoryFetching = false;
      state.isAllProductsByBrandFetching = false;
      state.isProductsByRandomCategoryFetching = false;
      state.isFetching = false;
      state.isAllBrandsFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.islatestProductFetching = false;
      state.isRandomBrandsFetching = false;
      state.isAllPopularProductFetching = false;
      state.isAllProductByGenderFetching = false;
    },
    
    clearProductData: (state) => {
      state.products = [];
      state.product = {};
      state.inventorySummary = {};
      state.productSummary = {};
      state.latestProducts = [];
      state.catProducts = [];
      state.allBrands = [];
      state.allBrandProducts = [];
      state.totalLatestProduct = 0;
      state.totalBrandProducts = 0;
      state.totalBrandItems = 0;
      state.universalSearch = [];
      state.productRating = 0;
      state.allGenderProducts = [];
      state.totalGenderProducts = 0;
      state.allPopularProduct = [];
      state.ProductsByRandomCategory = [];
      state.randomBrands = [];
      state.totalProductsByRandomCategory=0;
      state.totalPopularProducts=0
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.products=[...state.products ,payload.product]
        // state.products.push(...payload.product)
        state.successMessage = payload.message || 'Product created successfully';
      })
      .addCase(createProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message || 'Failed to create product';
      })
      
      .addCase(getAllProducts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        // state.isSuccess = true;
        state.products = payload.products;
        state.totalProducts = payload.totalProducts;
      })
      .addCase(getAllProducts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getProductsByRandomCategory.pending, (state) => {
        state.isProductsByRandomCategoryFetching = true;
      })
      .addCase(getProductsByRandomCategory.fulfilled, (state, { payload }) => {
        state.isProductsByRandomCategoryFetching = false;
        state.isSuccess = true;
        state.ProductsByRandomCategory = payload;
        state.totalProductsByRandomCategory=payload?.totalProduct
      
      })
      .addCase(getProductsByRandomCategory.rejected, (state, { payload }) => {
        state.isProductsByRandomCategoryFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getRandomBrands.rejected, (state, { payload }) => {
        state.isRandomBrandsFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getRandomBrands.pending, (state) => {
        state.isRandomBrandsFetching = true;
      })
      .addCase(getRandomBrands.fulfilled, (state, { payload }) => {
        state.isRandomBrandsFetching = false;
        // state.isSuccess = true;
        state.randomBrands = payload.brands;
        
      })
      .addCase(getAllPopularProduct.rejected, (state, { payload }) => {
        state.isAllPopularProductFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getAllPopularProduct.pending, (state) => {
        state.isAllPopularProductFetching = true;
      })
      .addCase(getAllPopularProduct.fulfilled, (state, { payload }) => {
        state.isAllPopularProductFetching = false;
        // state.isSuccess = true;
        state.allPopularProduct = payload.popularProducts;
         state.totalPopularProducts =payload.totalProducts
      })
      .addCase(getUniversalSearch.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUniversalSearch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        // state.isSuccess = true;
        state.universalSearch = payload;
     })
      .addCase(getUniversalSearch.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getAllBrands.pending, (state) => {
        state.isAllBrandsFetching = true;
      })
      .addCase(getAllBrands.fulfilled, (state, { payload }) => {
        state.isAllBrandsFetching = false;
        // state.isSuccess = true;
        state.allBrands =payload?.brands
        state.totalBrandProducts = payload.totalBrands;
      })
      .addCase(getAllBrands.rejected, (state, { payload }) => {
        state.isAllBrandsFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getAllProductsByGender.pending, (state) => {
        state.isAllProductByGenderFetching = true;
      })
      .addCase(getAllProductsByGender.fulfilled, (state, { payload }) => {
        state.isAllProductByGenderFetching = false;
        // state.isSuccess = true;
        state.allGenderProducts = payload.products;
        state.totalGenderProducts = payload.totalProducts;
      })
      .addCase(getAllProductsByGender.rejected, (state, { payload }) => {
        state.isAllProductByGenderFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getAllProductByCatId.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllProductByCatId.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        // state.isSuccess = true;
        state.catProducts = payload.products;
        state.totalProducts = payload.totalProducts;
      })
      .addCase(getAllProductByCatId.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getAllProductsByBrand.pending, (state) => {
        state.isAllProductsByBrandFetching = true;
      })
      .addCase(getAllProductsByBrand.fulfilled, (state, { payload }) => {
        state.isAllProductsByBrandFetching = false;
        // state.isSuccess = true;
        state.allBrandProducts = payload.products;
         state.totalBrandItems = payload.totalProducts;
      })
      .addCase(getAllProductsByBrand.rejected, (state, { payload }) => {
        state.isAllProductsByBrandFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })
      
      .addCase(getProductById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProductById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        // state.isSuccess = true;
        state.product = payload.product;
      })
      .addCase(getProductById.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch product';
      })
      
      .addCase(updateProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Product updated successfully';
        const index = state.products.findIndex(p => p._id === payload.product._id);
        if (index !== -1) {
          state.products[index] = payload.product;
        }
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update product';
      })
      
      
      .addCase(deleteProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Product deleted successfully';
        state.products = state.products.filter(p => p._id !== payload.productId);
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to delete product';
      })

      .addCase(getInventorySummary.pending, (state) => {
        state.isInventoryFetching = true;
      })
      .addCase(getInventorySummary.fulfilled, (state, { payload }) => {
        state.isInventoryFetching = false;
        // state.isSuccess = true;
        state.inventorySummary = payload;
     
      })
      .addCase(getInventorySummary.rejected, (state, { payload }) => {
        state.isInventoryFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      
      })
      .addCase(getProductSummary.pending, (state) => {
        state.isProductSummaryFetching = true;
      })
      .addCase(getProductSummary.fulfilled, (state, { payload }) => {
        state.isProductSummaryFetching = false;
        // state.isSuccess = true;
        state.productSummary = payload;
      
      })
      .addCase(getProductSummary.rejected, (state, { payload }) => {
        state.isProductSummaryFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getLatestProducts.pending, (state) => {
        state.islatestProductFetching = true;
      })
      .addCase(getLatestProducts.fulfilled, (state, { payload }) => {
        state.islatestProductFetching = false;
        // state.isSuccess = true;
        state.latestProducts = payload.products;
        state.totalLatestProduct=payload.total
      
      })
      .addCase(getLatestProducts.rejected, (state, { payload }) => {
        state.islatestProductFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(getBestSellingProducts.pending, (state) => {
        state.isBestSellingProductFetching = true;
      })
      .addCase(getBestSellingProducts.fulfilled, (state, { payload }) => {
        state.isBestSellingProductFetching = false;
        // state.isSuccess = true;
        state.bestSellingProducts = payload.products;
        state.totalBestSellingProduct=payload.totalCount
      
      })
      .addCase(getBestSellingProducts.rejected, (state, { payload }) => {
        state.isBestSellingProductFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch products';
      })

      .addCase(setProductRating.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(setProductRating.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productRating=payload?.rating;
        state.successMessage = payload.message || 'Product Rated successfully';
      })
      .addCase(setProductRating.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to rate product';
      })
    },
});

export const { clearProductState } = productSlice.actions;
export const selectProductState = (state) => state.product;
export default productSlice;
