import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from "../redux/store";
import PrivateRoute from './PrivateRoutes';
import ComponentLoader from '../Components/ComponentLoader/ComponentLoader';
import { AuthProvider } from './AuthContext';

import SearchPage from '../Pages/SearchResultPage';
import MyProfile from '../Pages/MyProfile';
import MyOrders from '../Pages/MyOrders';
import BestSellingProduct from '../Pages/BestSellingProducts';
// Lazy load components
const LandingPage = lazy(() => import('../Pages/LandingPage'));
const ClothingPage = lazy(() => import('../Pages/Clothing'));
const ProductDescription = lazy(() => import('../Pages/ProductDescription'));
const AboutUs = lazy(() => import('../Pages/AboutUs'));
const ContactUs = lazy(() => import('../Pages/ContactUs'));
const CheckoutPage = lazy(() => import('../Pages/CheckoutPage'));
const SignUp = lazy(() => import('../Pages/SignUp'));
const Login = lazy(() => import('../Pages/Login'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const Inventory = lazy(() => import('../Pages/Inventory'));
const BillingInvoices = lazy(() => import('../Pages/BillingInvoices'));
const Order = lazy(() => import('../Pages/Order'));
const Report = lazy(() => import('../Pages/Reports'));
const Customer = lazy(() => import('../Pages/Customers'));
const CustomerDetail = lazy(() => import('../Pages/CustomerDetail'));
const ProductAdminDetail = lazy(() => import('../Pages/ProductAdminDetail'));
const Terms = lazy(() => import('../Pages/Terms'));
const Refund = lazy(() => import('../Pages/Refund'));
const PrivacyPolicy = lazy(() => import('../Pages/PrivacyPolicy'));
const Guidlines = lazy(() => import('../Pages/Guidlines'));
const CompanyProfile = lazy(() => import('../Pages/CompanyProfile'));
const LatestProducts = lazy(() => import('../Pages/LatestProduct'));
const Category = lazy(() => import('../Pages/Category'));
const Products = lazy(() => import('../Pages/Products'));
const Supplier = lazy(() => import('../Pages/Supplier'));
const AllBrands = lazy(() => import('../Pages/AllBrands'));
const BrandProducts = lazy(() => import('../Pages/BrandProducts'));
const Men = lazy(() => import('../Pages/Men'));
const Women = lazy(() => import('../Pages/Women'));
const DiscoverCollection = lazy(() => import('../Pages/DiscoverCollection'));
const AllProductsUser = lazy(() => import('../Pages/AllProductsUser'));
const Favourites =lazy(()=>import( '../Pages/Favourites'));
// Private Route component

const AppRoutes = ({isLoggedIn}) => {
  return (
  
      <Suspense fallback={<ComponentLoader/>}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/clothing" element={<ClothingPage />} />
          <Route path="/allProducts/:id" element={<AllProductsUser />} />
          <Route path="/productdesc/:id" element={<ProductDescription />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/checkout/:id?" element={<PrivateRoute element={<CheckoutPage />} roles={['admin', 'user']} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/guidlines" element={<Guidlines />} />
          <Route path="/profile" element={<CompanyProfile />} />
          <Route path="/latestProduct" element={<LatestProducts />} />
          <Route path="/bestSelling" element={<BestSellingProduct/>} />
          <Route path='/allBrands' element={<AllBrands />} />
          <Route path='/brandProduct/:brandName' element={<BrandProducts />} />
          <Route path='/navproducts/:id' element={<Men />} />
          <Route path='/women' element={<Women />} />
          <Route path='/discoverCollection' element={<DiscoverCollection />} />
          <Route path='/favourites' element={<Favourites/>} />
          <Route path='/myprofile' element={<MyProfile/>}/>
          <Route path='/myOrders' element={<MyOrders/>} />
          
          {/* Private Routes */}
          <Route path="/search/:query" element={<SearchPage />}/>
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} roles={['admin']} />} />
          <Route path="/inventory" element={<PrivateRoute element={<Inventory />} roles={['admin']} />} />
          <Route path="/billing-invoices" element={<PrivateRoute element={<BillingInvoices />} roles={['admin']} />} />
          <Route path="/order" element={<PrivateRoute element={<Order />} roles={['admin']} />} />
          <Route path="/reports" element={<PrivateRoute element={<Report />} roles={['admin']} />} />
          <Route path="/customer" element={<PrivateRoute element={<Customer />} roles={['admin']} />} />
          <Route path="/customer-detail/:id" element={<PrivateRoute element={<CustomerDetail />} roles={['admin']} />} />
          <Route path="/product-admin-desc/:id" element={<PrivateRoute element={<ProductAdminDetail />} roles={['admin']} />} />
          <Route path="/category" element={<PrivateRoute element={<Category />} roles={['admin']} />} />
          <Route path="/product/:id" element={<PrivateRoute element={<Products />} roles={['admin']} />} />
          <Route path="/supplier" element={<PrivateRoute element={<Supplier />} roles={['admin']} />} />
        </Routes>
      </Suspense>
    
  );
};

export default AppRoutes;
