import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";


const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;
let userId;
const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  userId=data?.user?._id
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (order, thunkAPI) => {
    try {
      setTokenValues();
      console.log("Order received:", order);

      const url = order.admin 
        ? `${apiUrl}/orders/createOrderByAdmin/${userId}`
        : `${apiUrl}/orders/createOrder/${userId}`;

      const requestBody = order.admin 
        ? JSON.stringify(order.newOrder)  // If admin, use newOrder
        : JSON.stringify(order);          // Otherwise, use the entire order object

      const response = await fetch(url, {
        method: "POST",
        headers,
        body: requestBody,
      });

      const data = await response.json();

      if (response.status === 201) {
        return data; // Success, return data
      } else {
        return thunkAPI.rejectWithValue(data); // Handle non-201 status codes
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get all orders
export const getAllOrders = createAsyncThunk(
  "order/getAllOrders",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      const { searchValue, pageNumber, perPageCount, ...filters } = params;

   
      const query = new URLSearchParams({
        searchQuery: searchValue,
        page: pageNumber,
        limit: perPageCount,
        ...filters
      }).toString();

      const response = await fetch(`${apiUrl}/orders/getAllOrders?${query}`, {
        method: "GET",
        headers,
      });
     
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get an order by ID
export const getOrderById = createAsyncThunk(
  "order/getOrderById",
  async (id, thunkAPI) => {
    try {
      setTokenValues();

      const url = id 
        ? `${apiUrl}/orders/getOrderById/${id}` 
        : `${apiUrl}/orders/getOrderById/${userId}`;

      const response = await fetch(url, {
        method: "GET",
        headers,
      });

      const data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// Update an order
export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (order, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/orders/updateOrder/${order.orderId}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(order),
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete an order
export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/orders/deleteOrder/${id}`, {
        method: "DELETE",
        headers,
      });
      const data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOrderSummary = createAsyncThunk(
    "order/getOrderSummary",
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/orders/getOrderSummary/?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
          method: "GET",
          headers,
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const getOrderVsDelivered = createAsyncThunk(
    "order/getOrderVsDelivered",
    async (obj, thunkAPI) => {
      try {
        setTokenValues();
        const response = await fetch(`${apiUrl}/orders/orderVsDelivered?startDate=${obj?.startDate}&endDate=${obj?.endDate}`, {
          method: "GET",
          headers,
        });
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const initialState = {
    isOrderVsDeliveredFetching:false,
    isOrderSummaryFetching:false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  orders: [],
  order: {},
  orderSummary:{},
  orderVsDelivered:[],
  totalOrder:0,
  orderDetails:[],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrderState: (state) => {
        state.isOrderVsDeliveredFetching=false;
        state.isOrderSummaryFetching=false;
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.orders = [];
      state.order = {};
      state.orderSummary={};
      state.orderVsDelivered={}
      state.orderDetails=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderDetails=payload?.order
        state.successMessage = payload.message || 'Order created successfully';
      })
      .addCase(createOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message || 'Failed to create order';
      })
      
      .addCase(getAllOrders.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllOrders.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orders = payload.orders ;
        state.totalOrder=payload?.totalOrders
      })
      .addCase(getAllOrders.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch orders';
      })
      
      .addCase(getOrderById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getOrderById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.order = payload.orders;
        state.totalOrder=30
      })
      .addCase(getOrderById.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch order';
      })
      
      .addCase(updateOrder.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Order updated successfully';
        const index = state.orders.findIndex(o => o._id === payload.orders._id);
        if (index !== -1) {
          state.orders[index] = payload.orders;
        }
      })
      .addCase(updateOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update order';
      })
      
      .addCase(deleteOrder.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Order deleted successfully';
        state.orders = state.orders.filter(o => o._id !== payload.orderId);
      })
      .addCase(deleteOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to delete order';
      })
      .addCase(getOrderSummary.pending, (state) => {
        state.isOrderSummaryFetching = true;
      })
      .addCase(getOrderSummary.fulfilled, (state, { payload }) => {
        state.isOrderSummaryFetching = false;
        state.isSuccess = true;
        state.orderSummary = payload ;
       
      })
      .addCase(getOrderSummary.rejected, (state, { payload }) => {
        state.isOrderSummaryFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch orders';
      })
      .addCase(getOrderVsDelivered.pending, (state) => {
        state.isOrderVsDeliveredFetching = true;
      })
      .addCase(getOrderVsDelivered.fulfilled, (state, { payload }) => {
        state.isOrderVsDeliveredFetching = false;
        state.isSuccess = true;
        state.orderVsDelivered = payload?.data;
       
      })
      .addCase(getOrderVsDelivered.rejected, (state, { payload }) => {
        state.isOrderVsDeliveredFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch ';
      })
      
  },
});

export const { clearOrderState } = orderSlice.actions;
export const selectOrderState = (state) => state.order;
export default orderSlice;
