import React from 'react';

const CustomInput = ({ label, value, onChange, placeholder, error, type = 'text' }) => (
  <div className="mb-4 w-full">
    {label && (
      <label
        className="block text-gray-700 mb-1 py-3 uppercase"
        style={{
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '12px',
          textAlign: 'left',
        }}
      >
        {label}
      </label>
    )}

    {type === 'textarea' ? (
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        className={`border border-gray-300 rounded-md p-2 w-full ${error ? 'border-red-500' : ''}`}
        rows={4} // You can adjust the number of rows as needed
      />
    ) : (
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        className={`border border-gray-300 rounded-md p-2 w-full ${error ? 'border-red-500' : ''}`}
      />
    )}

    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

export default CustomInput;
