import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// API URL and token setup
const apiUrl = process.env.REACT_APP_BACKEND_URL;
let token;
let headers;

const setTokenValues = () => {
  const data = Cookies.get("loginData") ? JSON.parse(Cookies.get("loginData")) : null;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Create a new supplier
export const createSupplier = createAsyncThunk(
  "supplier/createSupplier",
  async (supplier, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/suppliers/createSupplier`, {
        method: "POST",
        headers,
        body: JSON.stringify(supplier),
      });
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get all suppliers
export const getAllSuppliers = createAsyncThunk(
  "supplier/getAllSuppliers",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/suppliers/getAllSuppliers`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get a supplier by ID
export const getSupplierById = createAsyncThunk(
  "supplier/getSupplierById",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/suppliers/getSupplierById/${id}`, {
        method: "GET",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update a supplier
export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async (supplier, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/suppliers/updateSupplier/${supplier._id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(supplier),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete a supplier
export const deleteSupplier = createAsyncThunk(
  "supplier/deleteSupplier",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${apiUrl}/suppliers/deleteSupplier/${id}`, {
        method: "DELETE",
        headers,
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  suppliers: [],
  supplier: {},
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    clearSupplierState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
      state.suppliers = [];
      state.supplier = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSupplier.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSupplier.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      
        state.successMessage = payload.message || 'Supplier created successfully';
      })
      .addCase(createSupplier.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to create supplier';
      })

      .addCase(getAllSuppliers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllSuppliers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.suppliers = payload;
      })
      .addCase(getAllSuppliers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch suppliers';
      })

      .addCase(getSupplierById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSupplierById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.supplier = payload.supplier;
      })
      .addCase(getSupplierById.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to fetch supplier';
      })

      .addCase(updateSupplier.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateSupplier.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Supplier updated successfully';
        const index = state.suppliers.findIndex(s => s._id === payload.supplier._id);
        if (index !== -1) {
          state.suppliers[index] = payload.supplier;
        }
      })
      .addCase(updateSupplier.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to update supplier';
      })

      .addCase(deleteSupplier.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSupplier.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = payload.message || 'Supplier deleted successfully';
        state.suppliers = state.suppliers.filter(s => s._id !== payload.supplierId);
      })
      .addCase(deleteSupplier.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error || 'Failed to delete supplier';
      })
  },
});

export const { clearSupplierState } = supplierSlice.actions;
export const selectSupplierState = (state) => state.supplier;
export default supplierSlice;
